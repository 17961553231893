import React from "react";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import ToggleButton from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";

import "./InputExplanation.css";

const InputExplanation = (props) => {
  const [showDetails, setShowDetails] = React.useState(false);

  return (
    <Container sx={{ marginTop: "2%", paddingBottom: "1%" }}>
      <ToggleButton
        value="check"
        selected={showDetails}
        onChange={() => {
          setShowDetails(!showDetails);
        }}
        variant="outlined"
        fullWidth
        height="100px"
        sx={{
          maxHeight: "40px",
          textTransform: "none",
          fontSize: "90%",
          color: "button.text",
          backgroundColor: "button.background",
          "&:hover": { backgroundColor: "button.hoverBackground" },
        }}
      >
        <HelpOutlineIcon sx={{ marginRight: "5px" }} /> Definition der
        Eingabe-Feldern <KeyboardArrowDownIcon />
      </ToggleButton>
      {showDetails && (
        <Container>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            <Box fontWeight="bold" display="inline">
              <Link href="https://de.wikipedia.org/wiki/Kaufpreis">
                Kaufpreis{" "}
              </Link>{" "}
            </Box>
            Kaufpreis ist der Preis, der von dir an den Verkäufer als
            Gegenleistung für den Kaufgegenstand (die Immobilie) in Geld zu
            entrichten ist.
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            <Box fontWeight="bold" display="inline">
              <Link href="https://de.wikipedia.org/wiki/Eigenkapital">
                Eigenkapital{" "}
              </Link>{" "}
            </Box>
            ist der Betrag von Vermögen, den du am Anfang des Kredits der Bank
            zahlst.
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            <Box fontWeight="bold" display="inline">
              <Link href="https://de.wikipedia.org/wiki/Kaufpreisaufteilung">
                Kaufpreisaufteilung{" "}
              </Link>{" "}
            </Box>
            eines bebauten Grundstückes ist erforderlich, um die
            Bemessungsgrundlage für{" "}
            <Link href="https://de.wikipedia.org/wiki/Absetzung_f%C3%BCr_Abnutzung">
              Absetzungen für Abnutzungen (AfA)
            </Link>{" "}
            zu ermitteln. Falls du es nicht weißt, kannst du grob mit 70%
            berechnen oder diesen{" "}
            <Link href="https://www.bundesfinanzministerium.de/Content/DE/Standardartikel/Themen/Steuern/Steuerarten/Einkommensteuer/2020-04-02-Berechnung-Aufteilung-Grundstueckskaufpreis.html">
              Link
            </Link>{" "}
            aufrufen.
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            <Box fontWeight="bold" display="inline">
              <Link href="https://de.wikipedia.org/wiki/Grunderwerbsteuer_(Deutschland)">
                Grunderwerbsteuer{" "}
              </Link>{" "}
            </Box>
            ist eine Steuer, die beim Erwerb eines Grundstücks oder
            Grundstückanteils anfällt. Sie ist unterschiedlich in verschiedenen
            Bundesländern.
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            <Box fontWeight="bold" display="inline">
              <Link href="https://de.wikipedia.org/wiki/Notarkosten">
                Notarkosten{" "}
              </Link>{" "}
            </Box>
            sind die Summe der für die Tätigkeit des Notars anfallenden
            Notargebühren, der Auslagen des Notars und der Umsatzsteuer.
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            <Box fontWeight="bold" display="inline">
              <Link href="https://de.wikipedia.org/wiki/Immobilienmakler#Maklerprovision">
                Maklerprovision{" "}
              </Link>{" "}
            </Box>
            ist ein prozentualer Anteil des Kaufpreises, den die
            Immobilienmaklern für ihre Dienstleistungen bezüglich des
            Verkaufsvon Immobilien bekommen.
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            <Box fontWeight="bold" display="inline">
              <Link href="https://de.wikipedia.org/wiki/Annuit%C3%A4t_(Investitionsrechnung)">
                Annuität{" "}
              </Link>{" "}
            </Box>
            ist den monatlichen Betrag, den du dem Darlehensgeber monatlich
            zahlst. Sie setzt sich aus Zins und Tilgung zusammen.
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            <Box fontWeight="bold" display="inline">
              <Link href="https://de.wikipedia.org/wiki/Zinsbindungsfrist">
                Sollzinsbindung{" "}
              </Link>{" "}
            </Box>
            ist die Zeit, für die der Zinssatz für dein Darlehen festgeschrieben
            wird.
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            <Box fontWeight="bold" display="inline">
              <Link href="https://de.wikipedia.org/wiki/Zinssatz">
                Zinssatz{" "}
              </Link>{" "}
            </Box>
            ist der in Prozent ausgedrückte Preis für Kapital ausgedrückt als
            Prozentangabe des Zinses.
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            <Box fontWeight="bold" display="inline">
              <Link href="https://de.wikipedia.org/wiki/Kaltmiete">
                Kaltmiete{" "}
              </Link>{" "}
            </Box>
            bezieht sich auf den Teil der Miete, der allein die Raumnutzung
            abdeckt. Heizkosten und sogenannte kalte Betriebskosten sind dabei
            nicht enthalten.
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            <Box fontWeight="bold" display="inline">
              <Link href="https://de.wikipedia.org/wiki/Hausgeld">
                Hausgeld{" "}
              </Link>{" "}
            </Box>
            (oder auch Wohngeld) ist eine aufgrund eines Wirtschaftsplans von
            Wohnungseigentümern geschuldete Vorschusszahlung, die an die
            Wohnungseigentümergemeinschaft zu zahlen ist.
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            <Box fontWeight="bold" display="inline">
              <Link href="https://de.wikipedia.org/wiki/Steuer">Steuer </Link>{" "}
            </Box>
            ist ein bestimmter Anteil deines Lohns, des Einkommens oder des
            Vermögens, der als öffentliche Leistung an den Staat abgeführt
            werden muss. Das weißt du schon.
          </Typography>
        </Container>
      )}
    </Container>
  );
};

export default InputExplanation;
