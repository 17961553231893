import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(120, 135, 62)",
      text: "rgb(63, 102, 62)",
      background: "rgba(221, 206, 206, 0.85)",
    },
    error: {
      main: red.A400,
    },
    button: {
      background: "rgb(120, 135, 62)",
      text: "white",
      hoverBackground: "rgb(120, 176, 62)",
    },
  },
});

export default theme;
