import React from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import "./InputFormKapital.css";

const InputFormKapital = (props) => {
  return (
    <Grid item xs={3}>
      <Typography
        variant="h6"
        sx={{
          color: "primary.text",
          flexGrow: 1,
          marginLeft: "5%",
          padding: "1%",
        }}
      >
        Kapital
      </Typography>
      <TextField
        label="Kaufpreis (€):"
        name="kaufpreis"
        variant="outlined"
        margin="dense"
        fullWidth
        value={props.kaufpreis}
        inputProps={{ type: "number" }}
        onChange={props.handleKaufpreis}
        required
      />
      <TextField
        label="Eigenkapital (€):"
        name="eigenkapital"
        variant="outlined"
        margin="dense"
        fullWidth
        value={props.eigenkapital}
        inputProps={{ type: "number" }}
        onChange={props.handleEigenkapital}
        required
      />
      <TextField
        label="Kaufpreisaufteilung (%):"
        name="kaufpreisaufteilung"
        variant="outlined"
        margin="dense"
        fullWidth
        value={props.kaufpreisaufteilung}
        inputProps={{ type: "number" }}
        InputProps={{ inputProps: { min: 0.0, max: 100.0 } }}
        onChange={props.handleKaufpreisaufteilung}
        required
      />
    </Grid>
  );
};

export default InputFormKapital;
