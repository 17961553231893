import React from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import "./InputFormKapital.css";

const InputFormKapital = (props) => {
  return (
    <Grid item xs={3}>
      <Typography
        variant="h6"
        sx={{
          color: "primary.text",
          flexGrow: 1,
          marginLeft: "5%",
          padding: "1%",
        }}
      >
        Nebenkosten
      </Typography>
      <TextField
        label="Grunderwerbsteuer (%):"
        name="grunderwerbsteuer"
        variant="outlined"
        margin="dense"
        fullWidth
        value={props.grunderwerbsteuer}
        inputProps={{ type: "number" }}
        onChange={props.handleGrunderwerbsteuer}
        required
      />
      <TextField
        label="Notar (%):"
        name="notar"
        variant="outlined"
        margin="dense"
        fullWidth
        value={props.notar}
        inputProps={{ type: "number" }}
        onChange={props.handleNotar}
        required
      />
      <TextField
        label="Makler (%):"
        name="makler"
        variant="outlined"
        margin="dense"
        fullWidth
        value={props.makler}
        inputProps={{ type: "number" }}
        onChange={props.handleMakler}
        required
      />
    </Grid>
  );
};

export default InputFormKapital;
