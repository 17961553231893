import React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import "./Summary.css";

const Summary = (props) => {
  const { eigenkapital, kaufpreis, sollzinsbindungJahre } = props.result.input;

  const {
    laufzeitMonate,
    restschuldenArray,
    immobilienEigentumTotalArray,
    steuerRuckgewinnPerAbschreibungTotalArray,
    selbstFinanzierungInsgesamtTotalArray,
    selbstFinanzierungVonZinsenTotalArray,
  } = props.result.output;
  const laufzeitJahre = laufzeitMonate / 12;

  return (
    <Container sx={{ marginTop: "2%" }}>
      <Box
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        sx={{
          padding: "2%",
          backgroundColor: "primary.background",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: "primary.text",
            flexGrow: 1,
            marginBottom: "1%",
          }}
        >
          Zusammenfassung
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: "primary.text",
            flexGrow: 1,
          }}
        >
          Bis Ende der Sollzinsbindung ({sollzinsbindungJahre} Jahre)
        </Typography>
        <List>
          <ListItemText
            sx={{
              color: "primary.text",
            }}
          >
            Der Restschuld wird{" "}
            <Box fontWeight="bold" display="inline">
              ~{Math.round(restschuldenArray[sollzinsbindungJahre * 12])} €{" "}
            </Box>
            betragen.
          </ListItemText>
          <ListItemText
            sx={{
              color: "primary.text",
            }}
          >
            <Box fontWeight="bold" display="inline">
              ~
              {Math.round(
                immobilienEigentumTotalArray[sollzinsbindungJahre * 12]
              )}{" "}
              %{" "}
            </Box>
            des Objekts wird dir gehören.
          </ListItemText>
          <ListItemText
            sx={{
              color: "primary.text",
            }}
          >
            Du wirst per Abschreibung der Kaufpreisaufteilung{" "}
            <Box fontWeight="bold" display="inline">
              ~
              {Math.round(
                steuerRuckgewinnPerAbschreibungTotalArray[
                  sollzinsbindungJahre * 12
                ]
              )}{" "}
              €{" "}
            </Box>
            aus Steuererklärung zurückbekommen.
          </ListItemText>
          <ListItemText
            sx={{
              color: "primary.text",
            }}
          >
            Du wirst insgesamt{" "}
            <Box fontWeight="bold" display="inline">
              ~
              {Math.round(
                selbstFinanzierungInsgesamtTotalArray[sollzinsbindungJahre * 12]
              )}{" "}
              €{" "}
            </Box>
            selber finanzieren, wobei davon
            <List sx={{ paddingLeft: "5%" }}>
              <ListItemText
                sx={{
                  color: "primary.text",
                }}
              >
                <Box fontWeight="bold" display="inline">
                  {eigenkapital} €{" "}
                </Box>
                dein Eigenkapital ist.
              </ListItemText>
              <ListItemText
                sx={{
                  color: "primary.text",
                }}
              >
                <Box fontWeight="bold" display="inline">
                  ~
                  {Math.round(
                    selbstFinanzierungVonZinsenTotalArray[
                      sollzinsbindungJahre * 12
                    ]
                  )}{" "}
                  €{" "}
                </Box>
                du für die Zinsen bezahlen wirst. was verschwendenes Geld ist!
              </ListItemText>
              <ListItemText
                sx={{
                  color: "primary.text",
                }}
              >
                <Box fontWeight="bold" display="inline">
                  ~
                  {Math.round(
                    selbstFinanzierungInsgesamtTotalArray[
                      sollzinsbindungJahre * 12
                    ] -
                      selbstFinanzierungVonZinsenTotalArray[
                        sollzinsbindungJahre * 12
                      ] -
                      eigenkapital
                  )}{" "}
                  €{" "}
                </Box>
                du für die Tilgung bezahlen wirst, was direkt für dein Objekt
                ist!
              </ListItemText>
            </List>
          </ListItemText>
        </List>
        <Typography
          variant="h6"
          sx={{
            color: "primary.text",
            flexGrow: 1,
          }}
        >
          Bis Ende der Laufzeit (~
          {Math.round(laufzeitJahre + Number.EPSILON)} Jahre)
        </Typography>

        <List>
          <ListItemText
            sx={{
              color: "primary.text",
            }}
          >
            Du wirst per Abschreibung der Kaufpreisaufteilung{" "}
            <Box fontWeight="bold" display="inline">
              ~
              {Math.round(
                steuerRuckgewinnPerAbschreibungTotalArray[laufzeitMonate]
              )}{" "}
              €{" "}
            </Box>
            aus Steuererklärung zurückbekommen.
          </ListItemText>
          <ListItemText
            sx={{
              color: "primary.text",
            }}
          >
            Du wirst insgesamt{" "}
            <Box fontWeight="bold" display="inline">
              ~
              {Math.round(
                selbstFinanzierungInsgesamtTotalArray[laufzeitMonate]
              )}{" "}
              €{" "}
            </Box>
            selber finanzieren, wobei davon
            <List sx={{ paddingLeft: "5%" }}>
              <ListItemText
                sx={{
                  color: "primary.text",
                }}
              >
                <Box fontWeight="bold" display="inline">
                  ~{eigenkapital} €{" "}
                </Box>
                dein Eigenkapital ist.
              </ListItemText>
              <ListItemText
                sx={{
                  color: "primary.text",
                }}
              >
                <Box fontWeight="bold" display="inline">
                  ~
                  {Math.round(
                    selbstFinanzierungVonZinsenTotalArray[laufzeitMonate]
                  )}{" "}
                  €{" "}
                </Box>
                du für die Zinsen bezahlen wirst, was verschwendenes Geld ist!
              </ListItemText>
              <ListItemText
                sx={{
                  color: "primary.text",
                }}
              >
                <Box fontWeight="bold" display="inline">
                  ~
                  {Math.round(
                    selbstFinanzierungInsgesamtTotalArray[laufzeitMonate] -
                      selbstFinanzierungVonZinsenTotalArray[laufzeitMonate] -
                      eigenkapital
                  )}{" "}
                  €{" "}
                </Box>
                du für die Tilgung bezahlen wirst, was direkt für dein Objekt
                ist!
              </ListItemText>
            </List>
          </ListItemText>
          <ListItemText
            sx={{
              color: "primary.text",
            }}
          >
            Mit dem selbst finanzierten Betrag von{" "}
            <Box fontWeight="bold" display="inline">
              ~
              {Math.round(
                selbstFinanzierungInsgesamtTotalArray[laufzeitMonate]
              )}{" "}
              €{" "}
            </Box>
            wirst du ein Objekt kaufen, was heute{" "}
            <Box fontWeight="bold" display="inline">
              {kaufpreis} €{" "}
            </Box>
            wert ist. Falls der Wert deines Objekts
            <List sx={{ paddingLeft: "5%" }}>
              <ListItemText
                sx={{
                  color: "primary.text",
                }}
              >
                gar nicht steigt, profitierst du trotzdem{" "}
                <Box fontWeight="bold" display="inline">
                  ~
                  {Math.round(
                    kaufpreis -
                      selbstFinanzierungInsgesamtTotalArray[laufzeitMonate]
                  )}{" "}
                  €
                </Box>
                .
              </ListItemText>
              <ListItemText
                sx={{
                  color: "primary.text",
                }}
              >
                jährlich{" "}
                <Box fontWeight="bold" display="inline">
                  um 2%{" "}
                </Box>
                steigt, wird es{" "}
                <Box fontWeight="bold" display="inline">
                  ~{Math.round(kaufpreis * Math.pow(1.02, laufzeitJahre))} €{" "}
                </Box>
                wert und profitierst du{" "}
                <Box fontWeight="bold" display="inline">
                  ~
                  {Math.round(
                    kaufpreis * Math.pow(1.02, laufzeitJahre) -
                      selbstFinanzierungInsgesamtTotalArray[laufzeitMonate]
                  )}{" "}
                  €
                </Box>
                .
              </ListItemText>
            </List>
          </ListItemText>
        </List>
      </Box>
    </Container>
  );
};

export default Summary;
