import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import InputExplanation from "./InputExplanation";
import InputFormKapital from "./InputFormKapital";
import InputFormKredit from "./InputFormKredit";
import InputFormMiete from "./InputFormMiete";
import InputFormNebenkosten from "./InputFormNebenkosten";

import "./InputForm.css";

const validationSchema = Yup.object().shape({
  salary: Yup.number()
    .min(10000, "Too Short!")
    .max(1000000, "Too Long!")
    .required("Required"),
});

const InputForm = (props) => {
  const navigate = useNavigate();

  // Get the current URL
  const url = new URL(window.location.href);
  // Get the query parameters
  const params = new URLSearchParams(url.search);

  // --- Kapital --- //
  // Eigenkapital
  const eigenkapitalInput = params.get("eigenkapital");
  const [eigenkapital, setEigenkapital] = useState(
    eigenkapitalInput ? eigenkapitalInput : 100000
  );
  const handleEigenkapital = (e) => {
    setEigenkapital(e.target.value);
  };

  // Kaufpreis
  const kaufpreisInput = params.get("kaufpreis");
  const [kaufpreis, setKaufpreis] = useState(
    kaufpreisInput ? kaufpreisInput : 300000
  );
  const handleKaufpreis = (e) => {
    setKaufpreis(e.target.value);
  };

  // Kaufpreisaufteilung
  const kaufpreisaufteilungInput = params.get("kaufpreisaufteilung");
  const [kaufpreisaufteilung, setKaufpreisaufteilung] = useState(
    kaufpreisaufteilungInput ? kaufpreisaufteilungInput : 70.0
  );
  const handleKaufpreisaufteilung = (e) => {
    setKaufpreisaufteilung(e.target.value);
  };

  // --- Nebenkosten --- //
  // Grunderwerbsteuer
  const grunderwerbsteuerInput = params.get("grunderwerbsteuer");
  const [grunderwerbsteuer, setGrunderwerbsteuer] = useState(
    grunderwerbsteuerInput ? grunderwerbsteuerInput : 3.57
  );
  const handleGrunderwerbsteuer = (e) => {
    setGrunderwerbsteuer(e.target.value);
  };

  // Notar
  const notarInput = params.get("notar");
  const [notar, setNotar] = useState(notarInput ? notarInput : 2.0);
  const handleNotar = (e) => {
    setNotar(e.target.value);
  };

  // Makler
  const maklerInput = params.get("makler");
  const [makler, setMakler] = useState(maklerInput ? maklerInput : 3.5);
  const handleMakler = (e) => {
    setMakler(e.target.value);
  };

  // --- Kredit --- //
  // Annuitat
  const annuitatInput = params.get("annuitat");
  const [annuitat, setAnnuitat] = useState(
    annuitatInput ? annuitatInput : 1200
  );
  const handleAnnuitat = (e) => {
    setAnnuitat(e.target.value);
  };

  // ZinssatzJahrlich
  const zinssatzJahrlichInput = params.get("zinssatzJahrlich");
  const [zinssatzJahrlich, setZinssatzJahrlich] = useState(
    zinssatzJahrlichInput ? zinssatzJahrlichInput : 5.0
  );
  const handleZinssatzJahrlich = (e) => {
    setZinssatzJahrlich(e.target.value);
  };

  // SollzinsbindungJahre
  const sollzinsbindungJahreInput = params.get("sollzinsbindungJahre");
  const [sollzinsbindungJahre, setSollzinsbindungJahre] = useState(
    sollzinsbindungJahreInput ? sollzinsbindungJahreInput : 15
  );
  const handleSollzinsbindungJahre = (e) => {
    setSollzinsbindungJahre(e.target.value);
  };

  // --- Miete --- //
  // KaltMieteMonatlich
  const kaltMieteMonatlichInput = params.get("kaltMieteMonatlich");
  const [kaltMieteMonatlich, setKaltMieteMonatlich] = useState(
    kaltMieteMonatlichInput ? kaltMieteMonatlichInput : 1000
  );
  const handleKaltMieteMonatlich = (e) => {
    setKaltMieteMonatlich(e.target.value);
  };

  // HausgeldMonatlich
  const hausgeldMonatlichInput = params.get("hausgeldMonatlich");
  const [hausgeldMonatlich, setHausgeldMonatlich] = useState(
    hausgeldMonatlichInput ? hausgeldMonatlichInput : 200
  );
  const handleHausgeldMonatlich = (e) => {
    setHausgeldMonatlich(e.target.value);
  };

  // Steuerprozent
  const steuerprozentInput = params.get("steuerprozent");
  const [steuerprozent, setSteuerprozent] = useState(
    steuerprozentInput ? steuerprozentInput : 40
  );
  const handleSteuerprozent = (e) => {
    setSteuerprozent(e.target.value);
  };

  const getFormInput = () => {
    return {
      eigenkapital: eigenkapital,
      kaufpreis: kaufpreis,
      kaufpreisaufteilung: kaufpreisaufteilung,
      grunderwerbsteuer: grunderwerbsteuer,
      notar: notar,
      makler: makler,
      annuitat: annuitat,
      zinssatzJahrlich: zinssatzJahrlich,
      sollzinsbindungJahre: sollzinsbindungJahre,
      kaltMieteMonatlich: kaltMieteMonatlich,
      hausgeldMonatlich: hausgeldMonatlich,
      steuerprozent: steuerprozent,
    };
  };

  const handleSubmit = () => {
    props.getFormInput(getFormInput());

    // Create new URL params to change the site URL
    const urlParams = {
      eigenkapital: eigenkapital,
      kaufpreis: kaufpreis,
      kaufpreisaufteilung: kaufpreisaufteilung,
      grunderwerbsteuer: grunderwerbsteuer,
      notar: notar,
      makler: makler,
      annuitat: annuitat,
      zinssatzJahrlich: zinssatzJahrlich,
      sollzinsbindungJahre: sollzinsbindungJahre,
      kaltMieteMonatlich: kaltMieteMonatlich,
      hausgeldMonatlich: hausgeldMonatlich,
      steuerprozent: steuerprozent,
    };

    // Send calculation parameters to New Relic as event
    if (window.NREUM && window.NREUM.addPageAction) {
      window.NREUM.addPageAction("BerechnenClicked", urlParams);
    }

    navigate(`?${new URLSearchParams(urlParams).toString()}`);
  };

  useEffect(() => {
    props.getFormInput(getFormInput());
  }, []);

  return (
    <Box
      margin="2%"
      alignItems="center"
      justifyContent="center"
      sx={{
        padding: "1%",
        backgroundColor: "primary.background",
      }}
    >
      <Formik
        initialValues={{}}
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => {
          return (
            <Form>
              <Grid alignItems="flex-start" container spacing={0}>
                <Grid container direction="row" item xs={12} spacing={0}>
                  <InputFormKapital
                    eigenkapital={eigenkapital}
                    handleEigenkapital={handleEigenkapital}
                    kaufpreis={kaufpreis}
                    handleKaufpreis={handleKaufpreis}
                    kaufpreisaufteilung={kaufpreisaufteilung}
                    handleKaufpreisaufteilung={handleKaufpreisaufteilung}
                  />
                  <InputFormNebenkosten
                    grunderwerbsteuer={grunderwerbsteuer}
                    handleGrunderwerbsteuer={handleGrunderwerbsteuer}
                    notar={notar}
                    handleNotar={handleNotar}
                    makler={makler}
                    handleMakler={handleMakler}
                  />
                  <InputFormKredit
                    annuitat={annuitat}
                    handleAnnuitat={handleAnnuitat}
                    zinssatzJahrlich={zinssatzJahrlich}
                    handleZinssatzJahrlich={handleZinssatzJahrlich}
                    sollzinsbindungJahre={sollzinsbindungJahre}
                    handleSollzinsbindungJahre={handleSollzinsbindungJahre}
                  />
                  <InputFormMiete
                    kaltMieteMonatlich={kaltMieteMonatlich}
                    handleKaltMieteMonatlich={handleKaltMieteMonatlich}
                    hausgeldMonatlich={hausgeldMonatlich}
                    handleHausgeldMonatlich={handleHausgeldMonatlich}
                    steuerprozent={steuerprozent}
                    handleSteuerprozent={handleSteuerprozent}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="outlined"
                fullWidth
                sx={{
                  textTransform: "none",
                  fontSize: "inherit",
                  color: "button.text",
                  backgroundColor: "button.background",
                  "&:hover": { backgroundColor: "button.hoverBackground" },
                }}
              >
                Berechnen!
              </Button>
            </Form>
          );
        }}
      </Formik>
      <InputExplanation />
    </Box>
  );
};

export default InputForm;
