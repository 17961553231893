import React from "react";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import "./Intro.css";

const Intro = (props) => {
  return (
    <Box
      marginTop="2%"
      marginLeft="15%"
      marginRight="15%"
      alignItems="center"
      justifyContent="center"
      sx={{
        padding: "%1",
        backgroundColor: "primary.background",
      }}
    >
      <Typography
        component="div"
        variant="h5"
        sx={{
          color: "primary.text",
          flexGrow: 1,
          padding: "1%",
        }}
      >
        <Box fontWeight="bold" display="inline">
          Willst du in Immobilien investieren?
        </Box>
      </Typography>
      <Typography
        component="div"
        sx={{
          color: "primary.text",
          flexGrow: 1,
          padding: "1%",
        }}
      >
        Du weißt, dass du es sich nicht leisten kannst, ein Objekt direkt mit
        Bar zu kaufen. Du hast nicht so viel Geld... Du siehst dir an, wie ein
        Kredit funktioniert, und findest das ziemlich cool. Du fängst an, zu
        recherchieren und findest eine Menge Websites, die dir die klassische
        "Restschuld"-Tabelle" zeigen.
      </Typography>
      <Typography
        component="div"
        sx={{
          color: "primary.text",
          flexGrow: 1,
          padding: "1%",
        }}
      >
        Aber, du willst mehr... Du willst verstehen, wie viel von deinen
        monatlichen Zahlungen an die Bank tatsächlich in dein Objekt fließt. Du
        willst wissen, welche steuerlichen Vorteile du haben wirst, wenn du dein
        Objekt vermietest. Langer Rede kurzer Sinn, Du willst berechnen:
        <List sx={{ paddingLeft: "5%" }}>
          <ListItemText
            sx={{
              color: "primary.text",
            }}
          >
            - wie viel du jeden Monat für dein Investment zahlen musst musst
          </ListItemText>
          <ListItemText
            sx={{
              color: "primary.text",
            }}
          >
            - wie hoch dein Gewinn am Ende sein wird
          </ListItemText>
        </List>
      </Typography>

      <Typography
        component="div"
        sx={{
          color: "primary.text",
          flexGrow: 1,
          padding: "1%",
        }}
      >
        <Box fontWeight="bold" display="inline">
          Diese Website ist genau das, was du suchst, mein Freund!
        </Box>
      </Typography>
    </Box>
  );
};

export default Intro;
