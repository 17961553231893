import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React from "react";

import { Line } from "react-chartjs-2";

import "./LineKumulativ.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineKumulativ = (props) => {
  // Set up opts
  const xTicks = props.chartOptions.xTicks;
  const pointRadius = props.chartOptions.points.radius;
  const {
    colorLine1,
    colorLine2,
    colorLine3,
    colorLine4,
    colorLine5,
    colorLine6,
    colorLine7,
  } = props.chartOptions.colors;
  const { drawSollzinsbindungLimit } = props.chartOptions.plugins;
  const {
    jahreArray,
    zinsenTotalArray,
    tilgungenTotalArray,
    steuerRuckgewinnPerAbschreibungTotalArray,
    nettoMieteEinkommenVorSteuerTotalArray,
    nettoMieteEinkommenNachSteuerTotalArray,
    selbstFinanzierungVonZinsenTotalArray,
    selbstFinanzierungInsgesamtTotalArray,
  } = props.output;

  // Chart options
  const kumulativeBetrachtungOptions = {
    responsive: true,
    scales: {
      x: xTicks,
      y: {
        ticks: {
          color: "black",
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "black",
        },
      },
      title: {
        display: true,
        text: "Kumulative Betrachtung (€)",
        color: "black",
        font: {
          size: 20,
        },
      },
    },
  };

  // Chart data
  const kumulativeBetrachtungData = {
    labels: jahreArray,
    datasets: [
      {
        label: "Zinsen",
        data: zinsenTotalArray,
        borderColor: colorLine1,
        backgroundColor: colorLine1,
        pointRadius: pointRadius,
      },
      {
        label: "Tilgung",
        data: tilgungenTotalArray,
        borderColor: colorLine2,
        backgroundColor: colorLine2,
        pointRadius: pointRadius,
      },
      {
        label: "Abschreibung-Steuergewinn",
        data: steuerRuckgewinnPerAbschreibungTotalArray,
        borderColor: colorLine3,
        backgroundColor: colorLine3,
        pointRadius: pointRadius,
      },
      {
        label: "Miete (vor Steuer)",
        data: nettoMieteEinkommenVorSteuerTotalArray,
        borderColor: colorLine4,
        backgroundColor: colorLine4,
        pointRadius: pointRadius,
      },
      {
        label: "Miete (nach Steuer)",
        data: nettoMieteEinkommenNachSteuerTotalArray,
        borderColor: colorLine5,
        backgroundColor: colorLine5,
        pointRadius: pointRadius,
      },
      {
        label: "Selbst finanziert (Zinsen)",
        data: selbstFinanzierungVonZinsenTotalArray,
        borderColor: colorLine6,
        backgroundColor: colorLine6,
        pointRadius: pointRadius,
      },
      {
        label: "Selbst finanziert (Insgesamt)",
        data: selbstFinanzierungInsgesamtTotalArray,
        borderColor: colorLine7,
        backgroundColor: colorLine7,
        pointRadius: pointRadius,
      },
    ],
  };

  return (
    <Line
      className="chart"
      options={kumulativeBetrachtungOptions}
      data={kumulativeBetrachtungData}
      plugins={[
        {
          afterDatasetsDraw: drawSollzinsbindungLimit,
        },
      ]}
    />
  );
};

export default LineKumulativ;
