import React from "react";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import ToggleButton from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";

import "./LineMonatlichExplanation.css";

const LineMonatlichExplanation = (props) => {
  const [showDetails, setShowDetails] = React.useState(false);

  const abschreibbarerAnteil = Math.round(
    (props.input.kaufpreisaufteilung / 100.0) *
      (props.input.kaufpreis + props.output.nebenkosten)
  );
  const abschreibbarerAnteilJahrlich = Math.round(0.02 * abschreibbarerAnteil);

  const einkommen = 100000.0;
  const steuer = (props.input.steuerprozent / 100.0) * einkommen;
  const steuerAfterAbschreibung = Math.round(
    (props.input.steuerprozent / 100.0) *
      (einkommen - abschreibbarerAnteilJahrlich)
  );

  const nettoMieteEinkommenVorSteuerMonatlich =
    props.input.kaltMieteMonatlich - props.input.hausgeldMonatlich;
  const nettoMieteEinkommenVorSteuerAmErstenMonat =
    nettoMieteEinkommenVorSteuerMonatlich - props.output.zinsenArray[0];
  const nettoMieteEinkommenNachSteuerAmErstenMonat =
    ((100.0 - props.input.steuerprozent) / 100.0) *
    nettoMieteEinkommenVorSteuerAmErstenMonat;

  return (
    <Container
      sx={{
        marginTop: "2%",
        paddingBottom: "1%",
      }}
    >
      <ToggleButton
        value="check"
        selected={showDetails}
        onChange={() => {
          setShowDetails(!showDetails);
        }}
        variant="outlined"
        fullWidth
        height="100px"
        sx={{
          maxHeight: "40px",
          textTransform: "none",
          fontSize: "90%",
          color: "button.text",
          backgroundColor: "button.background",
          "&:hover": { backgroundColor: "button.hoverBackground" },
        }}
      >
        <HelpOutlineIcon sx={{ marginRight: "5px" }} /> Erklärung des Diagramms{" "}
        <KeyboardArrowDownIcon />
      </ToggleButton>
      {showDetails && (
        <Container>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            Deine Annuität (monatliche Rate) ist{" "}
            <Box fontWeight="bold" display="inline">
              {props.input.annuitat} €
            </Box>
            , die du jeden Monat der Bank zahlen musst. Dieser Betrag setzt sich
            mit Zins und Tilgung zusammen, wobei die Anteile für die beiden sich
            jeden Monat ändern. Im Laufe der Zeit wird der Betrag, den du für
            die Zinsen zahlst, geringer und der Betrag für die Tilgungen höher.
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            Zum Beispiel: Am ersten Monat des Darlehens wirst du für die Zinsen{" "}
            <Box fontWeight="bold" display="inline">
              ~{Math.round(props.output.zinsenArray[0])} €{" "}
            </Box>
            und für die Tilgung{" "}
            <Box fontWeight="bold" display="inline">
              ~{Math.round(props.output.tilgungenArray[0])} €{" "}
            </Box>
            zahlen. Den Zinsanteil kannst du von deiner Perspektive als
            verschwendenes Geld sehen. Das ist was die Bank profitiert. Auf der
            anderen Seite entsprict der Tilgungsanteil deinem Investment. Damit
            besitzt du mehr von deinem Objekt.
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            Du vermietest dein Objekt für{" "}
            <Box fontWeight="bold" display="inline">
              ~{Math.round(props.input.kaltMieteMonatlich)} €{" "}
            </Box>
            kalt und musst der Wohngemeinschaft ein Hausgeld von{" "}
            <Box fontWeight="bold" display="inline">
              ~{Math.round(props.input.hausgeldMonatlich)} €{" "}
            </Box>
            zahlen. Das heißt, dein Nettomieteeinkommen ist{" "}
            <Box fontWeight="bold" display="inline">
              ~{nettoMieteEinkommenVorSteuerMonatlich} €
            </Box>
            . Dieser Betrag ist noch nicht versteuert.
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            Jetzt kriegst du zwei Steuer-Vorteile aus deinem
            Immobilien-Investment!
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            Der erste Vorteil kommt aus der{" "}
            <Link href="https://de.wikipedia.org/wiki/Absetzung_f%C3%BCr_Abnutzung">
              Absetzungen für Abnutzungen (AfA)
            </Link>{" "}
            . Das steht gewissermaßen für die Alterung deines Gebäudes. Du
            darfst jährlich{" "}
            <Box fontWeight="bold" display="inline">
              2 %{" "}
            </Box>
            des Gebäudewerts für{" "}
            <Box fontWeight="bold" display="inline">
              50 Jahre{" "}
            </Box>
            aus deinem Steuern absetzen.
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            Machen wir mal ein Beispiel. Dein Objekt hat einen Kaufpreis von{" "}
            <Box fontWeight="bold" display="inline">
              {props.input.kaufpreis} €{" "}
            </Box>
            und deine Nebenkosten betragen{" "}
            <Box fontWeight="bold" display="inline">
              ~{Math.round(props.output.nebenkosten)} €
            </Box>
            , was in Summe{" "}
            <Box fontWeight="bold" display="inline">
              ~{Math.round(props.input.kaufpreis + props.output.nebenkosten)} €{" "}
            </Box>
            entsprechen. Wenn du eine Kaufpreisaufteilung von{" "}
            <Box fontWeight="bold" display="inline">
              ~{props.input.kaufpreisaufteilung} %{" "}
            </Box>
            hast, heißt es, dass du{" "}
            <Box fontWeight="bold" display="inline">
              ~{abschreibbarerAnteil} €{" "}
            </Box>
            für 50 Jare (jährlich 2%) abschreiben kannst. 2% von diesem Betrag
            ist{" "}
            <Box fontWeight="bold" display="inline">
              ~{abschreibbarerAnteilJahrlich} €
            </Box>
            .
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            Sagen wir mal, dass du{" "}
            <Box fontWeight="bold" display="inline">
              100000 €{" "}
            </Box>
            Brutto im Jahr verdienst. Du hast dein Steuerprozent als{" "}
            <Box fontWeight="bold" display="inline">
              {props.input.steuerprozent} %{" "}
            </Box>
            gegeben. Davon ausgehend zahlst du{" "}
            <Box fontWeight="bold" display="inline">
              {Math.round(steuer)} €{" "}
            </Box>
            Steuern. Aber du hast aufgrund der Alterung deines Gebäudes{" "}
            <Box fontWeight="bold" display="inline">
              {abschreibbarerAnteilJahrlich} €{" "}
            </Box>
            Ausgaben in diesem Jahr. Deswegen ist dein Einkommen eigentlich{" "}
            <Box fontWeight="bold" display="inline">
              {einkommen - abschreibbarerAnteilJahrlich} €
            </Box>
            . Du solltest bezogen auf diesem Betrag{" "}
            <Box fontWeight="bold" display="inline">
              ~{Math.round(steuerAfterAbschreibung)} €{" "}
            </Box>
            Steuern zahlen! Diese Differenz von{" "}
            <Box fontWeight="bold" display="inline">
              ~{Math.round(steuer - steuerAfterAbschreibung)} €{" "}
            </Box>
            kriegst du wieder zurück per Steuererklärung. Das entspricht einem
            monatlichen Betrag von{" "}
            <Box fontWeight="bold" display="inline">
              ~{Math.round((steuer - steuerAfterAbschreibung) / 12.0)} €{" "}
            </Box>
            .
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            Der zweite Vorteil kommt aus den Zinsen. Dieser Anteil entspricht
            auch Ausgaben und daher per Steuererklärung absetztbar.
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            Du zahlst jeden Monat eine Annuität von{" "}
            <Box fontWeight="bold" display="inline">
              {props.input.annuitat} €{" "}
            </Box>
            . Nehmen wir den ersten Monat zum Beispiel. Da wirst du für die
            Zinsen{" "}
            <Box fontWeight="bold" display="inline">
              ~{Math.round(props.output.zinsenArray[0])} €{" "}
            </Box>
            zahlen und du kriegst ein Nettomieteeinkommen von{" "}
            <Box fontWeight="bold" display="inline">
              ~{nettoMieteEinkommenVorSteuerMonatlich} €{" "}
            </Box>
            . Normalerweise musstest du schon für das komplette Mieteeinkommen
            Steuer zahlen. Aber weil der Zinsanteil deine Ausgaben ist, zahlst
            du Steuern, nur wenn dein monatliches Mieteeinkommen mehr als der
            Zinsanteil ist.
          </Typography>
          {nettoMieteEinkommenNachSteuerAmErstenMonat <= Number.EPSILON && (
            <Typography
              component="div"
              sx={{
                color: "primary.text",
                flexGrow: 1,
                padding: "1%",
              }}
            >
              Da in diesem Fall der Zinsanteil höher als das Mieteeinkommen ist,
              zahlst du keine Steuern!
            </Typography>
          )}
          {nettoMieteEinkommenNachSteuerAmErstenMonat > Number.EPSILON && (
            <Typography
              component="div"
              sx={{
                color: "primary.text",
                flexGrow: 1,
                padding: "1%",
              }}
            >
              Da in diesem Fall das Mieteeinkommen{" "}
              <Box fontWeight="bold" display="inline">
                ~{Math.round(nettoMieteEinkommenVorSteuerAmErstenMonat)} €{" "}
              </Box>
              höher als der Zinsanteil ist, zahlst du Steuern nur für diesen
              Betrag nicht für das komplette Miete!
            </Typography>
          )}
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            Davon ausgehend kannst du oben sehen, wie viel du monatlich selber
            finanzieren sollst. Je weniger du die Zinsen selber finanzierst,
            desto besser. Lass deine Mieter für dich die Zinsen zahlen!
          </Typography>
        </Container>
      )}
    </Container>
  );
};

export default LineMonatlichExplanation;
