import React from "react";

import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";

import "./Header.css";

const Header = (props) => {
  return (
    <AppBar position="sticky">
      <Typography
        variant="h5"
        sx={{
          color: "white",
          flexGrow: 1,
          marginLeft: "5%",
          padding: "1%",
        }}
      >
        Immobilien-Kredit-Rechner
      </Typography>
    </AppBar>
  );
};

export default Header;
