import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";

import Container from "@mui/material/Container";

import Charts from "./components/chart/Charts";
import InputForm from "./components/form/InputForm";
import Header from "./components/header/Header";
import Intro from "./components/intro/Intro";
import Summary from "./components/summary/Summary";

import { calculate } from "./lib/calculator";

import "./App.css";

function App() {
  // Calculation result
  const [result, setResult] = useState();
  const handleCalculation = (formInput) => {
    const input = {
      eigenkapital: Number(formInput.eigenkapital),
      kaufpreis: Number(formInput.kaufpreis),
      kaufpreisaufteilung: Number(formInput.kaufpreisaufteilung),
      grunderwerbsteuer: Number(formInput.grunderwerbsteuer),
      notar: Number(formInput.notar),
      makler: Number(formInput.makler),
      annuitat: Number(formInput.annuitat),
      zinssatzJahrlich: Number(formInput.zinssatzJahrlich),
      sollzinsbindungJahre: Number(formInput.sollzinsbindungJahre),
      kaltMieteMonatlich: Number(formInput.kaltMieteMonatlich),
      hausgeldMonatlich: Number(formInput.hausgeldMonatlich),
      steuerprozent: Number(formInput.steuerprozent),
    };

    const output = calculate(
      input.eigenkapital,
      input.kaufpreis,
      input.kaufpreisaufteilung / 100.0,
      input.grunderwerbsteuer / 100.0,
      input.notar / 100.0,
      input.makler / 100.0,
      input.annuitat,
      input.zinssatzJahrlich / 100.0,
      input.sollzinsbindungJahre,
      input.kaltMieteMonatlich,
      input.hausgeldMonatlich,
      input.steuerprozent / 100.0
    );

    setResult({
      input,
      output,
    });
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Container maxWidth="s" disableGutters sx={{ marginBottom: "8%" }}>
            <Header />
            <Intro />
            <InputForm getFormInput={handleCalculation} />
            {result && <Charts result={result} />}
            {result && <Summary result={result} />}
          </Container>
        }
      />
    </Routes>
  );
}

export default App;
