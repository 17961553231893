import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React from "react";

import { Line } from "react-chartjs-2";

import Container from "@mui/material/Container";

import LineMonatlichExplanation from "./LineMonatlichExplanation";

import "./LineMonatlich.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineMonatlich = (props) => {
  // Set up opts
  const xTicks = props.chartOptions.xTicks;
  const pointRadius = props.chartOptions.points.radius;
  const {
    colorLine1,
    colorLine2,
    colorLine3,
    colorLine4,
    colorLine5,
    colorLine6,
    colorLine7,
  } = props.chartOptions.colors;
  const { drawSollzinsbindungLimit, drawAnnuitatMonatlich } =
    props.chartOptions.plugins;
  const {
    jahreArray,
    zinsenArray,
    tilgungenArray,
    steuerRuckgewinnPerAbschreibungArray,
    nettoMieteEinkommenVorSteuerArray,
    nettoMieteEinkommenNachSteuerArray,
    selbstFinanzierungVonZinsenArray,
    selbstFinanzierungInsgesamtArray,
  } = props.output;

  // Chart options
  const monatlicheBetrachtungOptions = {
    responsive: true,
    scales: {
      x: xTicks,
      y: {
        ticks: {
          color: "black",
        },
        position: "left",
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "black",
        },
      },
      title: {
        display: true,
        text: "Monatliche Betrachtung (€)",
        color: "black",
        font: {
          size: 20,
        },
      },
    },
  };

  // Chart data
  const monatlicheBetrachtungData = {
    labels: jahreArray,
    datasets: [
      {
        label: "Zinsen",
        data: zinsenArray,
        borderColor: colorLine1,
        backgroundColor: colorLine1,
        pointRadius: pointRadius,
      },
      {
        label: "Tilgung",
        data: tilgungenArray,
        borderColor: colorLine2,
        backgroundColor: colorLine2,
        pointRadius: pointRadius,
      },
      {
        label: "Abschreibung-Steuergewinn",
        data: steuerRuckgewinnPerAbschreibungArray,
        borderColor: colorLine3,
        backgroundColor: colorLine3,
        pointRadius: pointRadius,
      },
      {
        label: "Miete (vor Steuer)",
        data: nettoMieteEinkommenVorSteuerArray,
        borderColor: colorLine4,
        backgroundColor: colorLine4,
        pointRadius: pointRadius,
      },
      {
        label: "Miete (nach Steuer)",
        data: nettoMieteEinkommenNachSteuerArray,
        borderColor: colorLine5,
        backgroundColor: colorLine5,
        pointRadius: pointRadius,
      },
      {
        label: "Selbst finanziert (Zinsen)",
        data: selbstFinanzierungVonZinsenArray,
        borderColor: colorLine6,
        backgroundColor: colorLine6,
        pointRadius: pointRadius,
      },
      {
        label: "Selbst finanziert (Insgesamt)",
        data: selbstFinanzierungInsgesamtArray,
        borderColor: colorLine7,
        backgroundColor: colorLine7,
        pointRadius: pointRadius,
      },
    ],
  };

  return (
    <Container sx={{ marginTop: "2%", paddingBottom: "1%" }}>
      <Line
        className="chart"
        options={monatlicheBetrachtungOptions}
        data={monatlicheBetrachtungData}
        plugins={[
          {
            afterDatasetsDraw: drawSollzinsbindungLimit,
          },
          {
            afterDatasetsDraw: drawAnnuitatMonatlich,
          },
        ]}
      />
      <LineMonatlichExplanation
        input={props.input}
        output={props.output}
      />
    </Container>
  );
};

export default LineMonatlich;
