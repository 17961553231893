import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React from "react";

import Container from "@mui/material/Container";

import { Line } from "react-chartjs-2";

import LineEigentumExplanation from "./LineEigentumExplanation";

import "./LineEigentum.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineEigentum = (props) => {
  // Set up opts
  const xTicks = props.chartOptions.xTicks;
  const pointRadius = props.chartOptions.points.radius;
  const { colorLine1, colorLine2, colorLine3 } = props.chartOptions.colors;
  const { drawSollzinsbindungLimit } = props.chartOptions.plugins;
  const {
    jahreArray,
    restschuldenArray,
    tilgungenTotalArray,
    immobilienEigentumTotalArray,
  } = props.output;

  // Chart options
  const eigentumOptions = {
    responsive: true,
    scales: {
      x: xTicks,
      yLeft: {
        ticks: {
          color: "black",
        },
        title: {
          text: "Restschuld & Tilgung (€)",
          display: true,
          color: "black",
        },
        position: "left",
      },
      yRight: {
        ticks: {
          color: "black",
        },
        title: {
          text: "Eigentum (%)",
          display: true,
          color: "black",
        },
        position: "right",
        max: 100.0,
        min: 0.0,
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "black",
        },
      },
      title: {
        display: true,
        text: "Eigentum",
        color: "black",
        font: {
          size: 20,
        },
      },
    },
  };

  // Chart data
  const eigentumData = {
    labels: jahreArray,
    datasets: [
      {
        label: "Restschuld (€)",
        data: restschuldenArray,
        borderColor: colorLine1,
        backgroundColor: colorLine1,
        pointRadius: pointRadius,
        yAxisID: "yLeft",
      },
      {
        label: "Tilgung (€)",
        data: tilgungenTotalArray,
        borderColor: colorLine2,
        backgroundColor: colorLine2,
        pointRadius: pointRadius,
        yAxisID: "yLeft",
      },
      {
        label: "Immobilien Eigentum (%)",
        data: immobilienEigentumTotalArray,
        borderColor: colorLine3,
        backgroundColor: colorLine3,
        pointRadius: pointRadius,
        yAxisID: "yRight",
      },
    ],
  };

  return (
    <Container sx={{ marginTop: "2%", paddingBottom: "1%", height:"500px" }}>
      <Line
        className="chart"
        options={eigentumOptions}
        data={eigentumData}
        plugins={[
          {
            afterDatasetsDraw: drawSollzinsbindungLimit,
          },
        ]}
      />
      <LineEigentumExplanation input={props.input} output={props.output} />
    </Container>
  );
};

export default LineEigentum;
