import React from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import "./InputFormMiete.css";

const InputFormMiete = (props) => {
  return (
    <Grid item xs={3}>
      <Typography
        variant="h6"
        sx={{
          color: "primary.text",
          flexGrow: 1,
          marginLeft: "5%",
          padding: "1%",
        }}
      >
        Miete
      </Typography>
      <TextField
        label="Kaltmiete (€/Monat):"
        name="kaltmiete"
        variant="outlined"
        margin="dense"
        fullWidth
        value={props.kaltMieteMonatlich}
        inputProps={{ type: "number" }}
        onChange={props.handleKaltMieteMonatlich}
        required
      />
      <TextField
        label="Hausgeld (%/Jahr):"
        name="hausgeld"
        variant="outlined"
        margin="dense"
        fullWidth
        value={props.hausgeldMonatlich}
        inputProps={{ type: "number" }}
        onChange={props.handleHausgeldMonatlich}
        required
      />
      <TextField
        label="Steuer (%):"
        name="steuer"
        variant="outlined"
        margin="dense"
        fullWidth
        value={props.steuerprozent}
        inputProps={{ type: "number" }}
        InputProps={{ inputProps: { min: 0.0, max: 100.0 } }}
        onChange={props.handleSteuerprozent}
        required
      />
    </Grid>
  );
};

export default InputFormMiete;
