export function calculate(
  eigenkapital,
  kaufpreis,
  kaufpreisaufteilung,
  grunderwerbsteuer,
  notar,
  makler,
  annuitat,
  zinssatzJahrlich,
  sollzinsbindungJahre,
  kaltMieteMonatlich,
  hausgeldMonatlich,
  steuerprozent
) {
  const nebenkosten = kaufpreis * (grunderwerbsteuer + notar + makler);
  const darlehen = kaufpreis + nebenkosten - eigenkapital;
  const steuerRuckgewinnPerAbschreibungMonatlich =
    (0.02 * steuerprozent * kaufpreisaufteilung * (kaufpreis + nebenkosten)) /
    12;

  const zinssatzMonatlich = zinssatzJahrlich / 12.0;
  const q = 1.0 + zinssatzMonatlich;

  const laufzeitMonate =
    -Math.log(1.0 - (darlehen * zinssatzMonatlich) / annuitat) / Math.log(q);

  const nettoMieteEinkommenMonatlichVorSteuer =
    kaltMieteMonatlich - hausgeldMonatlich;

  // Define variables
  let jahreArray = [];
  let restschuldenArray = [];
  let zinsenArray = [];
  let zinsenTotalArray = [];
  let tilgungenArray = [];
  let tilgungenTotalArray = [];
  let immobilienEigentumTotalArray = [];
  let steuerRuckgewinnPerAbschreibungArray = [];
  let nettoMieteEinkommenVorSteuerArray = [];
  let nettoMieteEinkommenNachSteuerArray = [];
  let steuerRuckgewinnPerAbschreibungTotalArray = [];
  let nettoMieteEinkommenVorSteuerTotalArray = [];
  let nettoMieteEinkommenNachSteuerTotalArray = [];
  let selbstFinanzierungVonZinsenArray = [];
  let selbstFinanzierungVonZinsenTotalArray = [];
  let selbstFinanzierungInsgesamtArray = [];
  let selbstFinanzierungInsgesamtTotalArray = [];

  let zinsenTotal = 0.0;
  let tilgungenTotal = 0.0;
  let immobilienEigentumTotal = eigenkapital;
  let steuerRuckgewinnPerAbschreibungTotal = 0.0;
  let nettoMieteEinkommenVorSteuerTotal = 0.0;
  let nettoMieteEinkommenNachSteuerTotal = 0.0;
  let selbstFinanzierungVonZinsenTotal = 0.0;
  let selbstFinanzierungInsgesamtTotal = eigenkapital;

  for (let monat = 0; monat < laufzeitMonate; monat++) {
    // Zeit
    const jahr = monat / 12.0;
    jahreArray.push(jahr);

    // Restschuld & Zins & Tilgung im Monat
    const restschuld =
      darlehen * Math.pow(q, monat) -
      (annuitat * (Math.pow(q, monat) - 1.0)) / zinssatzMonatlich;

    const zins =
      ((darlehen * (Math.pow(q, laufzeitMonate) - Math.pow(q, monat - 1.0))) /
        (Math.pow(q, laufzeitMonate) - 1.0)) *
      zinssatzMonatlich;

    const tilgung =
      (darlehen * Math.pow(q, monat - 1.0) * zinssatzMonatlich) /
      (Math.pow(q, laufzeitMonate) - 1.0);

    restschuldenArray.push(restschuld);
    zinsenArray.push(zins);
    tilgungenArray.push(tilgung);

    // Kumulativ bezahlten Zinsen & Tilgungen
    zinsenTotal = zinsenTotal + zins;
    tilgungenTotal = tilgungenTotal + tilgung;

    zinsenTotalArray.push(zinsenTotal);
    tilgungenTotalArray.push(tilgungenTotal);

    // Immobilieneigentum
    immobilienEigentumTotal = immobilienEigentumTotal + tilgung;
    immobilienEigentumTotalArray.push(
      (immobilienEigentumTotal / (kaufpreis + nebenkosten)) * 100
    );

    // Rückgewinn per Steuererklärung über Abschreibung der Kaufpreisaufteilung
    const steuerRuckgewinnPerAbschreibungMonatlichIn50Years =
      monat < 50 * 12 ? steuerRuckgewinnPerAbschreibungMonatlich : 0.0;

    steuerRuckgewinnPerAbschreibungArray.push(
      steuerRuckgewinnPerAbschreibungMonatlichIn50Years
    );
    steuerRuckgewinnPerAbschreibungTotal =
      steuerRuckgewinnPerAbschreibungTotal +
      steuerRuckgewinnPerAbschreibungMonatlichIn50Years;
    steuerRuckgewinnPerAbschreibungTotalArray.push(
      steuerRuckgewinnPerAbschreibungTotal
    );

    // Miete-Einkommen
    nettoMieteEinkommenVorSteuerArray.push(
      nettoMieteEinkommenMonatlichVorSteuer
    );
    nettoMieteEinkommenVorSteuerTotal =
      nettoMieteEinkommenVorSteuerTotal + nettoMieteEinkommenMonatlichVorSteuer;
    nettoMieteEinkommenVorSteuerTotalArray.push(
      nettoMieteEinkommenVorSteuerTotal
    );

    // Selbst Finanzierung
    let nettoMieteEinkommenMonatlichNachSteuer =
      (nettoMieteEinkommenMonatlichVorSteuer - zins) * (1 - steuerprozent);

    if (nettoMieteEinkommenMonatlichNachSteuer < 0.0000001) {
      nettoMieteEinkommenMonatlichNachSteuer = 0.0;
      nettoMieteEinkommenNachSteuerArray.push(
        nettoMieteEinkommenMonatlichNachSteuer
      );

      nettoMieteEinkommenNachSteuerTotal =
        nettoMieteEinkommenNachSteuerTotal +
        nettoMieteEinkommenMonatlichNachSteuer;
      nettoMieteEinkommenNachSteuerTotalArray.push(
        nettoMieteEinkommenNachSteuerTotal
      );

      let selbstFinanzierungVonZinsen =
        zins -
        nettoMieteEinkommenMonatlichVorSteuer -
        steuerRuckgewinnPerAbschreibungMonatlich;
      selbstFinanzierungVonZinsen =
        selbstFinanzierungVonZinsen >= 0.0 ? selbstFinanzierungVonZinsen : 0.0;

      selbstFinanzierungVonZinsenArray.push(selbstFinanzierungVonZinsen);

      selbstFinanzierungVonZinsenTotal =
        selbstFinanzierungVonZinsenTotal + selbstFinanzierungVonZinsen;
      selbstFinanzierungVonZinsenTotalArray.push(
        selbstFinanzierungVonZinsenTotal
      );

      let selbstFinanzierungInsgesamt =
        tilgung +
        zins -
        nettoMieteEinkommenMonatlichVorSteuer -
        steuerRuckgewinnPerAbschreibungMonatlich;
      selbstFinanzierungInsgesamtArray.push(selbstFinanzierungInsgesamt);

      selbstFinanzierungInsgesamtTotal =
        selbstFinanzierungInsgesamtTotal + selbstFinanzierungInsgesamt;
      selbstFinanzierungInsgesamtTotalArray.push(
        selbstFinanzierungInsgesamtTotal
      );
    } else {
      nettoMieteEinkommenNachSteuerArray.push(
        nettoMieteEinkommenMonatlichNachSteuer
      );

      nettoMieteEinkommenNachSteuerTotal =
        nettoMieteEinkommenNachSteuerTotal +
        nettoMieteEinkommenMonatlichNachSteuer;
      nettoMieteEinkommenNachSteuerTotalArray.push(
        nettoMieteEinkommenNachSteuerTotal
      );

      let selbstFinanzierungVonZinsen = 0.0;
      selbstFinanzierungVonZinsenArray.push(selbstFinanzierungVonZinsen);

      let selbstFinanzierungVonZinsenBisEndeLaufzeit =
        monat === 0 ? 0.0 : selbstFinanzierungVonZinsenTotalArray[monat - 1];
      selbstFinanzierungVonZinsenTotalArray.push(
        selbstFinanzierungVonZinsenBisEndeLaufzeit
      );

      let selbstFinanzierungInsgesamt =
        tilgung -
        nettoMieteEinkommenMonatlichNachSteuer -
        steuerRuckgewinnPerAbschreibungMonatlich;
      selbstFinanzierungInsgesamtArray.push(selbstFinanzierungInsgesamt);

      selbstFinanzierungInsgesamtTotal =
        selbstFinanzierungInsgesamtTotal + selbstFinanzierungInsgesamt;
      selbstFinanzierungInsgesamtTotalArray.push(
        selbstFinanzierungInsgesamtTotal
      );
    }
  }

  return {
    // Nebenkosten
    nebenkosten: nebenkosten,

    // Darlehen
    darlehen: darlehen,

    // Laufzeit
    laufzeitMonate: Math.floor(laufzeitMonate),

    // Jahre
    jahreArray: jahreArray,

    // Tilgun
    tilgungenArray: tilgungenArray,
    tilgungenTotalArray: tilgungenTotalArray,

    // Zinsen
    zinsenArray: zinsenArray,
    zinsenTotalArray: zinsenTotalArray,

    // Restschuld
    restschuldenArray: restschuldenArray,

    // Steuerrückgewinn
    steuerRuckgewinnPerAbschreibungArray: steuerRuckgewinnPerAbschreibungArray,
    steuerRuckgewinnPerAbschreibungTotalArray:
      steuerRuckgewinnPerAbschreibungTotalArray,

    // Miete
    nettoMieteEinkommenVorSteuerArray: nettoMieteEinkommenVorSteuerArray,
    nettoMieteEinkommenVorSteuerTotalArray:
      nettoMieteEinkommenVorSteuerTotalArray,

    nettoMieteEinkommenNachSteuerArray: nettoMieteEinkommenNachSteuerArray,
    nettoMieteEinkommenNachSteuerTotalArray:
      nettoMieteEinkommenNachSteuerTotalArray,

    // Finanzierung
    selbstFinanzierungVonZinsenArray: selbstFinanzierungVonZinsenArray,
    selbstFinanzierungVonZinsenTotalArray:
      selbstFinanzierungVonZinsenTotalArray,

    selbstFinanzierungInsgesamtArray: selbstFinanzierungInsgesamtArray,
    selbstFinanzierungInsgesamtTotalArray:
      selbstFinanzierungInsgesamtTotalArray,

    // Immobilien Eigentum
    immobilienEigentumTotalArray: immobilienEigentumTotalArray,
  };
}
