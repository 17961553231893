import { PageAction } from "@newrelic/browser-agent/features/page_action";
import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import App from "./App";

import "./index.css";

import theme from "./theme";

// Instantiate New Relic agent
if (process.env.NODE_ENV === "production") {
  const options = {
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ["bam.eu01.nr-data.net"] },
    },
    info: {
      beacon: "bam.eu01.nr-data.net",
      errorBeacon: "bam.eu01.nr-data.net",
      licenseKey: "NRJS-d06c6a1d567cdcf8309",
      applicationID: "538543590",
      sa: 1,
    },
    loader_config: {
      accountID: "4275597",
      trustKey: "3487596",
      agentID: "538543590",
      licenseKey: "NRJS-d06c6a1d567cdcf8309",
      applicationID: "538543590",
    },
    features: [PageAction],
  };

  new BrowserAgent(options);
} else {
  const options = {
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ["bam.eu01.nr-data.net"] },
    },
    info: {
      beacon: "bam.eu01.nr-data.net",
      errorBeacon: "bam.eu01.nr-data.net",
      licenseKey: "NRJS-d06c6a1d567cdcf8309",
      applicationID: "538543588",
      sa: 1,
    },
    loader_config: {
      accountID: "4275597",
      trustKey: "3487596",
      agentID: "538543588",
      licenseKey: "NRJS-d06c6a1d567cdcf8309",
      applicationID: "538543588",
    },
    features: [PageAction],
  };

  new BrowserAgent(options);
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <App />
    </Router>
  </ThemeProvider>
);
