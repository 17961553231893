import React from "react";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import ToggleButton from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";

import "./LineEigentumExplanation.css";

const LineEigentumExplanation = (props) => {
  const [showDetails, setShowDetails] = React.useState(false);

  return (
    <Container
      sx={{
        marginTop: "2%",
        paddingBottom: "1%",
      }}
    >
      <ToggleButton
        value="check"
        selected={showDetails}
        onChange={() => {
          setShowDetails(!showDetails);
        }}
        variant="outlined"
        fullWidth
        height="100px"
        sx={{
          maxHeight: "40px",
          textTransform: "none",
          fontSize: "90%",
          color: "button.text",
          backgroundColor: "button.background",
          "&:hover": { backgroundColor: "button.hoverBackground" },
        }}
      >
        <HelpOutlineIcon sx={{ marginRight: "5px" }} /> Erklärung des Diagramms{" "}
        <KeyboardArrowDownIcon />
      </ToggleButton>
      {showDetails && (
        <Container>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            Du bringst ein Eigenkapital von{" "}
            <Box fontWeight="bold" display="inline">
              {props.input.eigenkapital} €{" "}
            </Box>
            ein und damit beginnst mit einem Restschuld von{" "}
            <Box fontWeight="bold" display="inline">
              {props.output.restschuldenArray[0]} €
            </Box>
            .
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            Das heißt aber nicht, dass du bis Ende des Darlehens insgesamt nur
            den Restschuld zahlen wirst! Noch dazu kommen die Zinskosten aber
            keine Sorge. Das beschreiben wir mit den unterigen Diagrammen.
          </Typography>
          <Typography
            component="div"
            sx={{
              color: "primary.text",
              flexGrow: 1,
              padding: "1%",
            }}
          >
            Der Restschuld entspricht, was du tilgen musst. Also,{" "}
            <Box fontStyle="italic" display="inline">
              (Kaufpreis + Nebenkosten - Eigenkapital)
            </Box>
            . Davon ausgehend gehört dir{" "}
            <Box fontWeight="bold" display="inline">
              ~{Math.round(props.output.immobilienEigentumTotalArray[0])} %
            </Box>{" "}
            des Objekts am Anfang des Darlehens.
          </Typography>
        </Container>
      )}
    </Container>
  );
};

export default LineEigentumExplanation;
