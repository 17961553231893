import React from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import "./InputFormKredit.css";

const InputFormKredit = (props) => {
  return (
    <Grid item xs={3}>
      <Typography
        variant="h6"
        sx={{
          color: "primary.text",
          flexGrow: 1,
          marginLeft: "5%",
          padding: "1%",
        }}
      >
        Kredit
      </Typography>
      <TextField
        label="Annuität (€/Monat):"
        name="annuitat"
        variant="outlined"
        margin="dense"
        fullWidth
        value={props.annuitat}
        inputProps={{ type: "number" }}
        onChange={props.handleAnnuitat}
        required
      />
      <TextField
        label="Sollzinsbindung (Jahre):"
        name="sollzinsbindung"
        variant="outlined"
        margin="dense"
        fullWidth
        value={props.sollzinsbindungJahre}
        inputProps={{ type: "number" }}
        onChange={props.handleSollzinsbindungJahre}
        required
      />
      <TextField
        label="Zinssatz (%/Jahr):"
        name="zinssatz"
        variant="outlined"
        margin="dense"
        fullWidth
        value={props.zinssatzJahrlich}
        inputProps={{ type: "number" }}
        onChange={props.handleZinssatzJahrlich}
        required
      />
    </Grid>
  );
};

export default InputFormKredit;
