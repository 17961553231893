import React from "react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import LineEigentum from "./LineEigentum";
import LineKumulativ from "./LineKumulativ";
import LineMonatlich from "./LineMonatlich";

// Line points
const pointRadius = 1.5;

// --- Colors --- //
const colorLine1 = "rgb(219, 114, 0)";
const colorLine2 = "rgb(0, 85, 236)";
const colorLine3 = "rgb(20, 229, 27)";
const colorLine4 = "rgb(104, 69, 27)";
const colorLine5 = "rgb(238, 215, 27)";
const colorLine6 = "rgb(121, 51, 185)";
const colorLine7 = "rgb(180, 203, 47)";

// x ticks
const xTicks = {
  ticks: {
    color: "black",
    callback: function (val) {
      return Number.isInteger(val / 12) ? this.getLabelForValue(val) : "";
    },
  },
};

const Charts = (props) => {
  const { input, output } = props.result;

  // Sollzinsbindung limit
  const drawSollzinsbindungLimit = function (chart) {
    const {
      ctx,
      chartArea: { top, bottom },
      scales: { x },
    } = chart;

    ctx.save();

    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = "red";
    ctx.setLineDash([10, 10]);

    ctx.moveTo(x.getPixelForValue(input.sollzinsbindungJahre * 12), top);
    ctx.lineTo(x.getPixelForValue(input.sollzinsbindungJahre * 12), bottom);
    ctx.stroke();
    ctx.restore();
  };

  // Annuitat monatlich
  const drawAnnuitatMonatlich = function (chart) {
    const {
      ctx,
      chartArea: { left, right },
      scales: { y },
    } = chart;

    ctx.save();

    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = "red";
    ctx.setLineDash([10, 10]);

    ctx.moveTo(left, y.getPixelForValue(input.annuitat));
    ctx.lineTo(right, y.getPixelForValue(input.annuitat));
    ctx.stroke();
    ctx.restore();
  };

  // Gather chart options
  const chartOptions = {
    xTicks,
    points: {
      radius: pointRadius,
    },
    colors: {
      colorLine1,
      colorLine2,
      colorLine3,
      colorLine4,
      colorLine5,
      colorLine6,
      colorLine7,
    },
    plugins: {
      drawSollzinsbindungLimit,
      drawAnnuitatMonatlich,
    },
  };

  return (
    <Container>
      <Box
        sx={{
          backgroundColor: "primary.background",
        }}
      >
        <LineEigentum
          input={input}
          output={output}
          chartOptions={chartOptions}
        />
        <LineMonatlich
          input={input}
          output={output}
          chartOptions={chartOptions}
        />
        <LineKumulativ
          input={input}
          output={output}
          chartOptions={chartOptions}
        />
      </Box>
    </Container>
  );
};

export default Charts;
